import * as yup from 'yup';

const emailSchema = yup
  .string()
  .required('Este campo é obrigatório')
  .email('Formato de email inválido');

const passwordShema = yup.string().min(8, 'É preciso ter pelo menos 8 caracteres');

export { emailSchema, passwordShema };
