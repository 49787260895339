import React from 'react';
import { Card, Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { PAYER_DATA } from 'GraphQL/Queries';
import { useQuery } from '@apollo/client';
import { formatAddress, formatToCpfCnpj } from 'Utils/formatters';
import { GetInTouch } from 'Components';
import useStyles from './styles';

const PaymentData = () => {
  const classes = useStyles();
  const { data } = useQuery(PAYER_DATA);
  const branch = data?.viewer?.branch;

  return (
    <Grid container justifyContent='center'>
      <Grid item lg={8} md={12} sm={12}>
        <Card elevation={3} className={classes.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                value={branch?.name || ''}
                disabled={!branch?.name}
                className={classes.textField}
                label='Nome da empresa pagadora'
                fullWidth
              />
            </Grid>
            <Grid item lg={8} xs={12}>
              <TextField
                value={formatToCpfCnpj(branch?.cnpj) || ''}
                disabled={!branch?.cnpj}
                className={classes.textField}
                label='CNPJ da empresa pagadora'
                fullWidth
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <TextField
                value={`Dia ${branch?.payday || '-'}`}
                disabled={!branch?.payday}
                className={classes.textField}
                label='Vencimento da fatura'
                fullWidth
              />
            </Grid>
            <Grid item lg={8} xs={12}>
              <TextField
                value={branch?.email || ''}
                disabled={!branch?.email}
                className={classes.textField}
                label='Email'
                fullWidth
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <TextField
                value={branch?.phone || ''}
                disabled={!branch?.phone}
                className={classes.textField}
                label='Telefone'
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={formatAddress(branch?.address)}
                disabled={!branch?.address}
                className={classes.textField}
                label='Endereço'
                fullWidth
              />
            </Grid>
            <GetInTouch message='Precisa alterar algum dado de pagamento?' />
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PaymentData;
