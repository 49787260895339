const colors = {
  grey: '#7C7C7C',
  lightGrey: '#DBDBDB',
  blue: '#1976D2',
  darkBlue: '#292D4A',
  red: '#f44336',
  green: '#4CAF50',
};
export const colorByStatus = (status) => {
  const STATUS_MAP = {
    PAID: colors.green,
    DELAYED: colors.red,
  };
  return STATUS_MAP[status];
};
export default colors;
