import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Tooltip, IconButton } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { PersonAdd } from '@material-ui/icons';
import { PROTHEUS_CODE, SUBSCRIPTIONS } from 'GraphQL/Queries';
import { useQuery, useLazyQuery } from '@apollo/client';
import CustomPagination from 'Screens/Account/Components/CustomPagination';
import { useDebounce } from 'use-lodash-debounce';
import { formatCurrency, formatDate, formatToCpfCnpj } from 'Utils/formatters';
import { useStyles } from './Styles';

const Subscriptions = () => {
  const { data: protheusData, error } = useQuery(PROTHEUS_CODE);
  const perPage = 10;
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [getSubscriptions, { data }] = useLazyQuery(SUBSCRIPTIONS, {
    variables: { page, perPage },
  });
  const [loading, setLoading] = useState(false);
  const debouncedValue = useDebounce(search, 500);
  const fetchMoreSubs = () => {
    let pageSearch = page;
    if (search) {
      pageSearch = 1;
    }
    setLoading(true);
    getSubscriptions({
      variables: { page: pageSearch, perPage, search },
    });
  };
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
    setSubscriptions(getItems());
    setTotalPages(getTotalPages());
  }, [data]);

  useEffect(() => {
    fetchMoreSubs();
  }, [page, debouncedValue]);

  const getItems = () => {
    if (data) {
      const { items } = data.viewer.branch.subscriptions;
      return items.map((sub) => {
        return [
          formatToCpfCnpj(sub.customer?.holder?.cpf),
          sub.customer?.car?.model || '-',
          sub.customer?.car?.chassis || '-',
          sub.customer?.car?.plate || '-',
          formatDate(sub.createdAt),
          formatCurrency(sub.price),
        ];
      });
    }
    return [];
  };
  const getTotalPages = () => {
    if (data) {
      const { count } = data.viewer.branch.subscriptions;
      return Math.ceil(count / perPage);
    }
    return 0;
  };
  const [subscriptions, setSubscriptions] = useState(getItems());
  const [totalPages, setTotalPages] = useState(getTotalPages());

  const addCustomer = () => {
    return (
      <Tooltip disableFocusListener title='Nova conta'>
        <IconButton onClick={() => handleRegistrationUrl()}>
          <PersonAdd />
        </IconButton>
      </Tooltip>
    );
  };
  const handleRegistrationUrl = () => {
    if (protheusData) {
      const url = getRegistrationURL(protheusData.viewer.branch.protheus);
      window.open(url, '_blank');
    }
  };
  const getRegistrationURL = (cssCode) => {
    return `${process.env.REACT_APP_REGISTRATION_URL}?css_code=${cssCode}`;
  };
  const customPagination = () => {
    return <CustomPagination totalPages={totalPages} page={page} setPage={setPage} />;
  };

  const classes = useStyles();
  const columns = ['CPF/CNPJ', 'Modelo', 'Chassi', 'Placa', 'Data de aquisição', 'Valor'];
  const options = {
    filter: false,
    serverSide: true,
    responsive: 'vertical',
    tableBodyHeight: '100%',
    tableBodyMaxHeight: '',
    print: false,
    download: false,
    viewColumns: false,
    sort: false,
    selectableRowsHideCheckboxes: true,
    textLabels: {
      body: {
        noMatch: loading ? <LinearProgress /> : 'Nenhum resultado foi encontrado',
      },
      toolbar: {
        search: 'Pesquisar',
      },
    },
    searchPlaceholder: 'Buscar por CPF, CNPJ,chassi ou placa',
    customToolbar: () => !error && addCustomer(),
    customFooter: () => customPagination(),
    onSearchChange: (searchText) => setSearch(searchText),
  };

  return (
    <React.Fragment>
      <MUIDataTable
        className={classes.root}
        title={'Lista de Assinaturas'}
        data={subscriptions}
        columns={columns}
        options={options}
      />
    </React.Fragment>
  );
};

export default Subscriptions;
