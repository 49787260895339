import { makeStyles } from '@material-ui/core/styles';
import colors from 'Utils/colors';

const useStyles = makeStyles({
  outBarcodeContainer: {
    padding: 16,
    backgroundColor: colors.lightGrey,
    color: colors.grey,
    display: 'flex',
    borderRadius: 4,
    marginBottom: 16,
    '& p': {
      marginLeft: 16,
    },
  },
  gridItem: {
    marginTop: 16,
    padding: 8,
    '& h1': {
      marginTop: 8,
      marginBottom: 8,
      fontSize: '3rem',
      color: colors.darkBlue,
    },
    '& p': {
      marginTop: 0,
      marginBottom: 0,
      color: colors.grey,
    },
    '& img': {
      width: '100%',
    },
  },
  leftCard: {
    padding: 24,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  rightCard: {
    height: '100%',
    maxWidth: '100%',
    padding: '24px 8px',
  },
});
export default useStyles;
