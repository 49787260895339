import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  card: {
    padding: 24,
    marginTop: 16,
    display: 'flex',
    flex: 1,
  },
  textField: {
    marginBottom: 8,
  },
});
export default useStyles;
