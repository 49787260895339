import React from 'react';
import MailIcon from '@material-ui/icons/Mail';
import useStyles from './styles';

const GetInTouch = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classes.getInTouch}>
      <p>{message}</p>
      <div className={classes.flexDivAlignCenter}>
        <MailIcon />
        <a href='mailto:contato@vai.com.vc'>Entre em contato conosco</a>
      </div>
    </div>
  );
};

export default GetInTouch;
