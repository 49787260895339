import { makeStyles } from '@material-ui/core/styles';
import colors from 'Utils/colors';

const useStyles = makeStyles({
  getInTouch: {
    '& p': {
      fontSize: '0.8rem',
      color: colors.grey,
      marginBottom: 4,
    },
    '& a': {
      marginLeft: 8,
      '&:visited': {
        color: 'inherit',
      },
    },
    color: colors.blue,
    marginTop: 16,
  },
  flexDivAlignCenter: {
    display: 'flex',
    alignItems: 'center',
  },
});
export default useStyles;
