import React from 'react';
import { useBarcode } from 'react-barcodes';
import contentCopy from 'Assets/content_copy.svg';
import { ButtonBase } from '@material-ui/core';
import useStyles from './styles';

const Barcode = ({ value, typefulLine }) => {
  const classes = useStyles();

  const { inputRef } = useBarcode({
    value,
    options: {
      background: '#ffffff',
      format: 'ITF',
      displayValue: false,
    },
  });

  return (
    <>
      <img ref={inputRef} />
      <ButtonBase
        disabled={!typefulLine}
        onClick={() => {
          navigator.clipboard.writeText(typefulLine);
        }}
        className={classes.barcodeBtnBase}
      >
        <p>{typefulLine}</p>
        <img src={contentCopy} />
      </ButtonBase>
    </>
  );
};

export default Barcode;
