import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation ($email: String!, $password: String!) {
    login(authInput: { email: $email, password: $password }) {
      authPayload {
        token
        dealerImageUrl
      }
    }
  }
`;
