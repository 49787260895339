import { useReducer } from 'react';

const useFormValidation = (formFields) => {
  const fieldsReducer = (state, action) => {
    if (action.initState) {
      return action.initState;
    } else {
      const newState = { ...state };
      newState[action.id] = {
        value: action.value,
        error: action.error,
      };
      return newState;
    }
  };

  const [fields, fieldsDispatch] = useReducer(fieldsReducer, {});

  const validate = async () => {
    const validResults = await Promise.all(
      formFields.map(async (formField) => {
        try {
          await formField.schema.validate(fields[formField.id]?.value);
          return true;
        } catch (errMsg) {
          fieldsDispatch({
            id: formField.id,
            value: fields[formField.id]?.value,
            error: errMsg.errors[0],
          });
          return false;
        }
      })
    );
    return !validResults.includes(false);
  };

  const getField = (field) => {
    return fields[field]?.value || '';
  };

  const getError = (field) => {
    return fields[field]?.error;
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    fieldsDispatch({ id, value });
  };
  return [getField, getError, handleChange, validate];
};

export default useFormValidation;
