import React from 'react';
import Router from 'Router';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import colors from 'Utils/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.darkBlue,
    },
    secondary: {
      main: colors.blue,
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Router />
      </div>
    </ThemeProvider>
  );
}

export default App;
