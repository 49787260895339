import React from 'react';
import { formatDate } from 'Utils/formatters';
import { INVOICE_STATUS } from 'Utils/enums';
import colors from 'Utils/colors';

const DateStatus = ({ status, dueDate, paymentDate, closingDate }) => {
  switch (status) {
    case INVOICE_STATUS.PAID:
      return <p style={{ color: colors.green }}>Pago em: {formatDate(paymentDate)}</p>;
    case INVOICE_STATUS.CLOSED:
      return <p>Vence em: {formatDate(dueDate)}</p>;
    case INVOICE_STATUS.DELAYED:
      return <p style={{ color: colors.red }}>Vencido em: {formatDate(dueDate)}</p>;
    default:
      return <p>Fecha em: {formatDate(closingDate)}</p>;
  }
};

export default DateStatus;
