import { gql } from '@apollo/client';

export const INVOICES = gql`
  query {
    viewer {
      branch {
        invoices {
          edges {
            node {
              bankslip {
                barcode
                typefulLine
                pdfUrl
              }
              id
              amount
              status
              closingDate
              paymentDate
              dueDate
              amount
            }
          }
        }
      }
    }
  }
`;

export const INVOICES_SUBSCRIPTIONS = gql`
  query ($invoiceId: ID!) {
    viewer {
      branch {
        invoice(id: $invoiceId) {
          subscriptions {
            edges {
              node {
                createdAt
                price
                status
                customer {
                  holder {
                    cpf
                  }
                  car {
                    model
                  }
                }
              }
            }
          }
          id
        }
      }
    }
  }
`;

export const INVOICE_NFSE = gql`
  query ($invoiceId: ID!) {
    viewer {
      branch {
        invoice(id: $invoiceId) {
          nfseXml
        }
      }
    }
  }
`;
