import gql from 'graphql-tag';

export const PROTHEUS_CODE = gql`
  query protheusCode {
    viewer {
      branch {
        protheus
      }
    }
  }
`;
