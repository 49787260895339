import React from 'react';

const Form = ({ children, onSubmit, ...rest }) => {
  return (
    <form
      noValidate
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit();
      }}
      {...rest}
    >
      {children}
    </form>
  );
};

export default Form;
