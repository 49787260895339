import React, { useEffect, useState } from 'react';
import { Grid, Card, Select, Button, MenuItem } from '@material-ui/core';
import useStyles from './styles';
import PaymentStatus from './Components/PaymentStatus';
import Invoice from './Components/Invoice';
import Barcode from './Components/Barcode';
import ShopTable from './Components/ShopTable';
import DateStatus from './Components/DateStatus';
import { INVOICES, INVOICES_SUBSCRIPTIONS, INVOICE_NFSE } from 'GraphQL/Queries';
import { useQuery, useLazyQuery } from '@apollo/client';
import { formatCurrency, formatMonth } from 'Utils/formatters';
import { INVOICE_STATUS } from 'Utils/enums';
import { RenderIf, GetInTouch } from 'Components';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const Payments = () => {
  const classes = useStyles();
  const [selectedMonth, setSelectedMonth] = useState('');
  const [availableMonths, setAvailableMonths] = useState([]);
  const { data: invoicesData } = useQuery(INVOICES);
  const [getInvoiceNFSE, { data: invoiceNFSE }] = useLazyQuery(INVOICE_NFSE);
  const [getSubscriptions, { data: subscripionsData }] = useLazyQuery(INVOICES_SUBSCRIPTIONS);
  const [currentInvoice, setCurrentInvoice] = useState();
  const invoices = invoicesData?.viewer?.branch?.invoices?.edges;
  const subscriptions = subscripionsData?.viewer?.branch?.invoice?.subscriptions?.edges;
  const [url, setUrl] = useState('');
  const [fileName, setFileName] = useState('file.xml');
  useEffect(() => {
    if (invoices?.length > 0) {
      let mainIndex = 0;
      invoices.forEach((invoice, index) => {
        if (invoice.node.status !== INVOICE_STATUS.PAID) {
          mainIndex = index;
        }
      });
      setSelectedMonth(mainIndex);
      setCurrentInvoice(invoices[mainIndex].node);
      setAvailableMonths(invoices.map((item) => formatMonth(item.node?.dueDate)));
    }
  }, [invoices]);

  useEffect(() => {
    if (currentInvoice) {
      getSubscriptions({ variables: { invoiceId: currentInvoice.id } });
      getInvoiceNFSE({ variables: { invoiceId: currentInvoice.id } });
    }
  }, [currentInvoice]);

  useEffect(() => {
    if (invoiceNFSE) {
      try {
        const invoiceXML = window.atob(invoiceNFSE?.viewer?.branch?.invoice?.nfseXml);
        setFileName(`NF-${availableMonths[selectedMonth]}.xml`);
        generateURL(invoiceXML);
      } catch (e) {
        console.log(e);
      }
    }
  }, [invoiceNFSE]);

  const generateURL = (invoiceXML) => {
    if (invoiceXML) {
      const data = new Blob([invoiceXML], { type: 'text/plain' });
      if (url !== null) {
        window.URL.revokeObjectURL(url);
      }
      setUrl(window.URL.createObjectURL(data));
    }
  };

  return (
    <Grid container>
      <Grid item className={classes.gridItem} xl={4} lg={4} md={12} xs={12}>
        <Card elevation={3} className={classes.leftCard}>
          <Select
            labelId='month-select-label'
            id='month-select'
            value={selectedMonth}
            disableUnderline
            onChange={(event) => {
              const monthIndex = event.target.value;
              setCurrentInvoice(invoices[monthIndex].node);
              setSelectedMonth(monthIndex);
            }}
          >
            {availableMonths.map((month, index) => (
              <MenuItem key={month + index} value={index}>
                {month}
              </MenuItem>
            ))}
          </Select>
          <PaymentStatus status={currentInvoice?.status} />
          <h1>{formatCurrency(currentInvoice?.amount)}</h1>
          <DateStatus
            status={currentInvoice?.status}
            dueDate={currentInvoice?.dueDate}
            paymentDate={currentInvoice?.paymentDate}
            closingDate={currentInvoice?.closingDate}
          />
          <Invoice url={url} fileName={fileName} />
          <RenderIf condition={currentInvoice?.bankslip?.barcode}>
            <Barcode
              value={currentInvoice?.bankslip?.barcode}
              typefulLine={currentInvoice?.bankslip?.typefulLine}
            />
          </RenderIf>
          <RenderIf condition={!currentInvoice?.bankslip?.barcode}>
            <div className={classes.outBarcodeContainer}>
              <ErrorOutlineIcon />
              <p>
                O código de barras para pagamento ficará disponível após o fechamento da fatura.
              </p>
            </div>
          </RenderIf>
          <Button
            disabled={!currentInvoice?.bankslip?.pdfUrl}
            href={currentInvoice?.bankslip?.pdfUrl}
            variant='contained'
            color='secondary'
          >
            Baixar boleto
          </Button>
          <GetInTouch message='Problemas com a sua Nota Fiscal?' />
        </Card>
      </Grid>
      <Grid item className={classes.gridItem} xl={8} lg={8} md={12} xs={12}>
        <Card elevation={2} className={classes.rightCard}>
          <ShopTable rows={subscriptions || []} currentInvoice={currentInvoice} />
        </Card>
      </Grid>
    </Grid>
  );
};

export default Payments;
