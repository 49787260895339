const formatCurrency = (amount) =>
  amount?.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }) || '-';

const formatDate = (dateString) => {
  if (dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('pt-BR');
  } else {
    return '-';
  }
};

const capitalizeString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const formatMonth = (dateString) => {
  const date = new Date(dateString);
  return capitalizeString(date.toLocaleString('pt-BR', { month: 'long', year: 'numeric' }));
};

const formatCPF = (cpf) => cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');

const formatPlanStatus = (status) => (status === 'ANNUAL' ? 'Anual' : 'Mensal');

const formatAddress = (address) => {
  if (address) {
    const { street, number, city, state } = address;
    return `${street}, ${number || 'SN'} - ${city.name}, ${state.uf}`;
  } else {
    return '-';
  }
};

const formatToCpfCnpj = (value) => {
  let strValue = value;
  if (strValue) {
    strValue = strValue.replace(/\D/g, '');

    if (strValue.length <= 14) {
      strValue = strValue.replace(/(\d{3})(\d)/, '$1.$2');
      strValue = strValue.replace(/(\d{3})(\d)/, '$1.$2');
      strValue = strValue.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
    } else {
      strValue = strValue.replace(/^(\d{2})(\d)/, '$1.$2');
      strValue = strValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3');
      strValue = strValue.replace(/\.(\d{3})(\d)/, '.$1/$2');
      strValue = strValue.replace(/(\d{4})(\d)/, '$1-$2');
    }
    return strValue;
  }
  return '-';
};
export {
  formatCurrency,
  formatDate,
  formatMonth,
  formatCPF,
  formatPlanStatus,
  formatToCpfCnpj,
  formatAddress,
};
