import React, { useState } from 'react';
import { TabBar, RenderIf } from 'Components';
import { Subscriptions, Payments, PaymentData } from './Tabs';
import { Grid } from '@material-ui/core';

const Account = ({ logout }) => {
  const [tabIndex, setTabIndex] = useState(1);

  return (
    <>
      <header>
        <TabBar
          logout={logout}
          tabIndex={tabIndex}
          handleTabChange={(_, index) => setTabIndex(index)}
          tabs={['Assinaturas', 'Pagamentos', 'Dados de Pagamento']}
        />
      </header>
      <Grid container justifyContent='center' alignItems='center'>
        <Grid item lg={10} md={12} sm={12}>
          <RenderIf condition={tabIndex === 0}>
            <Subscriptions />
          </RenderIf>
          <RenderIf condition={tabIndex === 1}>
            <Payments />
          </RenderIf>
          <RenderIf condition={tabIndex === 2}>
            <PaymentData />
          </RenderIf>
        </Grid>
      </Grid>
    </>
  );
};

export default Account;
