import React from 'react';
import {
  Grid,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from '@material-ui/core';
import { formatCurrency, formatDate, formatToCpfCnpj, formatPlanStatus } from 'Utils/formatters';
import CsvDownloader from 'react-csv-downloader';
import GetAppIcon from '@material-ui/icons/GetApp';
import useStyles from './styles';
import Tooltip from '@material-ui/core/Tooltip';

const ShopTable = ({ rows, currentInvoice }) => {
  const classes = useStyles();
  if (rows.length) {
    const datas = rows.map((row) => {
      return {
        cpfCnpj: formatToCpfCnpj(row.node.customer?.holder?.cpf),
        car: row.node.customer?.car?.model || '-',
        createdAt: formatDate(row.node.createdAt),
        price: row.node.price,
        plan: formatPlanStatus(row.node.status),
      };
    });
    const columns = [
      {
        id: 'cpfCnpj',
        displayName: 'CPF/CNPJ',
      },
      {
        id: 'car',
        displayName: 'Veículo',
      },
      {
        id: 'createdAt',
        displayName: 'Data de início',
      },
      {
        id: 'price',
        displayName: 'Valor',
      },
      {
        id: 'plan',
        displayName: 'Plano',
      },
    ];
    return (
      <Grid container>
        <Grid item xs={6}>
          <h4 className={classes.ShopTableHeader}>Assinaturas desta fatura</h4>
        </Grid>
        <Grid item xs={6}>
          <Icon />
          <CsvDownloader
            className={classes.csvDownloader}
            columns={columns}
            datas={datas}
            filename={`fatura-${currentInvoice?.dueDate}`}
            extension='.csv'
            separator=';'
          >
            <Tooltip title='Download'>
              <IconButton aria-label='download'>
                <GetAppIcon />
              </IconButton>
            </Tooltip>
          </CsvDownloader>
        </Grid>
        <Grid item xs={12}>
          <TableContainer className={classes.shopTableContainer}>
            <Table stickyHeader aria-label='tabela de compras'>
              <TableHead>
                <TableRow tabIndex={-1}>
                  <TableCell>CPF/CNPJ</TableCell>
                  <TableCell align='left'>Veículo</TableCell>
                  <TableCell align='left'>Data de início</TableCell>
                  <TableCell align='right'>Valor</TableCell>
                  <TableCell align='left'>Plano</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={row.cpf + index}>
                    <TableCell component='th' scope='row'>
                      {formatToCpfCnpj(row.node.customer?.holder?.cpf)}
                    </TableCell>
                    <TableCell align='left'>{row.node.customer?.car?.model || '-'}</TableCell>
                    <TableCell align='left'>{formatDate(row.node.createdAt)}</TableCell>
                    <TableCell align='right'>{formatCurrency(row.node.price)}</TableCell>
                    <TableCell align='left'>{formatPlanStatus(row.node.status)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <div className={classes.fullFlexCenterContainer}>
        <p>Ainda não temos nenhuma assinatura vinculada a esta fatura.</p>
      </div>
    );
  }
};

export default ShopTable;
