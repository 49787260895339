import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AuthenticationGuard = ({ children, token, requireAuth, ...rest }) => {
  const shouldRedirect = requireAuth ? !token : token;
  const redirectLink = requireAuth ? '/login' : '/';
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (shouldRedirect) {
          return (
            <Redirect
              to={{
                pathname: redirectLink,
                state: { from: location },
              }}
            />
          );
        } else {
          return children;
        }
      }}
    />
  );
};

export default AuthenticationGuard;
