import { useState } from 'react';
const useAuth = () => {
  const [token, setToken] = useState(localStorage.getItem('token'));

  const saveToken = (userToken) => {
    localStorage.setItem('token', userToken);
    setToken(userToken);
  };

  const removeToken = () => {
    localStorage.removeItem('token');
    setToken();
  };

  return {
    setToken: saveToken,
    removeToken,
    token,
  };
};

export default useAuth;
