import React, { useState } from 'react';
import { Paper, Tabs, Tab, Grid, IconButton, Button } from '@material-ui/core';
import logoVai from 'Assets/logo-vai.svg';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from '@material-ui/core/Popover';

const TabBar = ({ tabIndex, handleTabChange, tabs = [], logout }) => {
  const [anchor, setAnchor] = useState();
  return (
    <Paper square>
      <Grid container>
        <Grid item lg={1} style={{ textAlign: 'center' }}>
          <img src={logoVai} style={{ width: '40%', maxWidth: 100, marginTop: 8 }} alt='Logo Vai' />
        </Grid>
        <Grid item lg={10} md={12}>
          <h3>Gerenciar Contas</h3>
          <Tabs
            value={tabIndex}
            indicatorColor='primary'
            textColor='primary'
            onChange={handleTabChange}
            aria-label='Barra de navegação'
          >
            {tabs.map((tab, index) => (
              <Tab key={tab + index} label={tab} />
            ))}
          </Tabs>
        </Grid>
        <Grid item lg={1} style={{ textAlign: 'center' }}>
          <IconButton
            onClick={(event) => {
              setAnchor(event.currentTarget);
            }}
            aria-label='mais'
          >
            <MoreVertIcon />
          </IconButton>
          <Popover
            open={!!anchor}
            anchorEl={anchor}
            onClose={() => {
              setAnchor();
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Button onClick={logout}>sair</Button>
          </Popover>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TabBar;
