import gql from 'graphql-tag';

export const SUBSCRIPTIONS = gql`
  query subscriptions($page: Int!, $perPage: Int!, $search: String) {
    viewer {
      branch {
        subscriptions(page: $page, perPage: $perPage, search: $search) {
          count
          items {
            id
            createdAt
            price
            customer {
              holder {
                cpf
              }
              car {
                model
                chassis
                plate
              }
            }
          }
        }
      }
    }
  }
`;
