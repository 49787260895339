import { makeStyles } from '@material-ui/core/styles';
import colors from 'Utils/colors';

const useStyles = makeStyles({
  shopTableContainer: {
    maxHeight: '25vw',
  },
  fullFlexCenterContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    color: colors.grey,
    fontSize: '1rem',
    marginTop: 8,
  },
  barcodeBtnBase: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 8,
    '& p': {
      fontSize: '0.9rem',
      textAlign: 'center',
    },
    '& img': {
      width: 16,
      marginLeft: 8,
    },
  },
  invoiceContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 8,
    color: (props) => props.color,
    fontSize: '1rem',
    '& a': {
      '&:visited': {
        color: 'inherit',
      },
    },
    '& p': {
      marginLeft: 4,
    },
  },
  statusText: {
    margin: 0,
    marginLeft: 4,
  },
  scrollTable: {
    overflowX: 'auto',
    overflowY: 'unset',
  },
  csvDownloader: {
    float: 'right',
    marginRight: 15,
    justifyContent: 'flex-end',
    marginTop: 4.8,
  },
  ShopTableHeader: {
    marginLeft: 15,
    justifyContent: 'flex-start',
  },
});
export default useStyles;
