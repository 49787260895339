import React, { useState } from 'react';
import { LOGIN } from 'GraphQL/Mutations';
import { useMutation } from '@apollo/client';
import useFormValidation from 'Hooks/useFormValidation';
import { Grid, TextField, Button } from '@material-ui/core';
import logoVai from 'Assets/logo-vai.svg';
import { Form, FeedbackText } from 'Components';
import { emailSchema, passwordShema } from 'Utils/validationSchemas';
import CircularProgress from '@material-ui/core/CircularProgress';
import translateError from 'Utils/translateError';

const Login = ({ setToken }) => {
  const [loginMutation, { loading }] = useMutation(LOGIN);
  const [getField, getError, onChange, validate] = useFormValidation([
    { id: 'email', schema: emailSchema },
    { id: 'password', schema: passwordShema },
  ]);
  const [credentialsError, setCredentialError] = useState();

  const handleLogin = async (auth) => {
    try {
      const isEmailValid = await validate();
      if (isEmailValid) {
        const { data } = await loginMutation({ variables: { ...auth } });
        setToken(data?.login?.authPayload?.token);
      }
    } catch (error) {
      setCredentialError(translateError(error.message));
    }
  };

  return (
    <Grid
      container
      justifyContent='center'
      alignItems='center'
      style={{ height: '100vh', textAlign: 'center' }}
    >
      <Grid item xl={3} lg={4} md={6} xs={10}>
        <img src={logoVai} style={{ height: 100 }} alt='Logo Vai' />
        <Form
          noValidate
          onSubmit={() => {
            handleLogin({ email: getField('email'), password: getField('password') });
          }}
        >
          <TextField
            label='Email'
            type='email'
            margin='normal'
            disabled={loading}
            fullWidth
            id='email'
            value={getField('email')}
            error={!!getError('email')}
            helperText={getError('email')}
            onChange={onChange}
            onBlur={validate}
          />
          <TextField
            label='Senha'
            type='password'
            margin='normal'
            disabled={loading}
            fullWidth
            id='password'
            value={getField('password')}
            error={!!getError('password')}
            helperText={getError('password')}
            onChange={onChange}
            onBlur={validate}
          />
          <FeedbackText display={!!credentialsError} severity='error' text={credentialsError} />
          <Button
            style={{ margin: 8 }}
            disabled={loading}
            size='large'
            color='primary'
            variant='contained'
            type='submit'
          >
            {loading ? <CircularProgress size={24} /> : 'Entrar'}
          </Button>
        </Form>
      </Grid>
    </Grid>
  );
};

export default Login;
