import React from 'react';
import Alert from '@material-ui/lab/Alert';

const FeedbackText = ({ display, severity, text }) => {
  if (display) {
    return <Alert severity={severity}>{text}</Alert>;
  } else {
    return null;
  }
};
export default FeedbackText;
