import React from 'react';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Pagination from '@material-ui/lab/Pagination';
import { useStyles } from './Styles';

const CustomPagination = (props) => {
  const classes = useStyles();
  return (
    <TableFooter>
      <TableRow>
        <TableCell>
          <div className={classes.root}>
            <Pagination
              count={props.totalPages}
              page={props.page}
              color='primary'
              onChange={(e, value) => props.setPage(value)}
            />
          </div>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default CustomPagination;
