import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import UpdateIcon from '@material-ui/icons/Update';
import useStyles from './styles';
import { colorByStatus } from 'Utils/colors';
import { INVOICE_STATUS } from 'Utils/enums';

const IconStatus = ({ status }) => {
  if (status === INVOICE_STATUS.PAID) {
    return <CheckIcon fontSize='inherit' />;
  } else {
    return <UpdateIcon fontSize='inherit' />;
  }
};

const getStatusText = (status) => {
  const STATUS_MAP = {
    PAID: 'Pagamento realizado',
    CLOSED: 'Aguardando pagamento',
    OPEN: 'Aguardando fechamento da fatura',
    DELAYED: 'Pagamento atrasado',
    default: 'Status de pagamento indefinido',
  };
  return STATUS_MAP[status] || STATUS_MAP.default;
};

const PaymentStatus = ({ status }) => {
  const classes = useStyles();

  return (
    <div style={{ color: colorByStatus(status) }} className={classes.statusContainer}>
      <IconStatus status={status} />
      <p style={{ color: colorByStatus(status) }} className={classes.statusText}>
        {getStatusText(status)}
      </p>
    </div>
  );
};

export default PaymentStatus;
