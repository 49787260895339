import React from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import useStyles from './styles';
import colors from 'Utils/colors';

const InvoiceText = ({ url, fileName }) => {
  if (url) {
    return (
      <a href={url} download={fileName}>
        Baixar nota fiscal
      </a>
    );
  } else {
    return <p>Nota fiscal não disponível</p>;
  }
};

const Invoice = ({ url, fileName }) => {
  const classes = useStyles({ color: url ? colors.blue : colors.lightGrey });
  return (
    <div className={classes.invoiceContainer}>
      <DescriptionIcon fontSize='inherit' />
      <InvoiceText url={url} fileName={fileName} />
    </div>
  );
};
export default Invoice;
