import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import Login from '../Screens/Login';
import AuthenticationGuard from './AuthenticationGuard';
import Account from 'Screens/Account';
import useAuth from 'Hooks/useAuth';

const Router = () => {
  const { setToken, token, removeToken } = useAuth();
  return (
    <BrowserRouter>
      <Switch>
        <AuthenticationGuard token={token} path='/login'>
          <Login setToken={setToken} />
        </AuthenticationGuard>
        <AuthenticationGuard token={token} requireAuth path='/'>
          <Account logout={removeToken} />
        </AuthenticationGuard>
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
