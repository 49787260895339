import { gql } from '@apollo/client';

export const PAYER_DATA = gql`
  query {
    viewer {
      branch {
        payday
        name
        cnpj
        email
        phone
        address {
          city {
            name
          }
          state {
            uf
          }
          street
          number
        }
      }
    }
  }
`;
